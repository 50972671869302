<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <NewCouponForm
          v-if="!loadingUsers"
          @coupon-saved="getCoupons"
          :users="users"
        />
        <v-progress-linear
          v-else
          indeterminate/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="coupons"
          :loading="loading"
          class="elevation-1"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.type="{ item }">
            {{ item.type === 1 ? 'Sleva v %' : 'Konkrétní částka' }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewCouponForm from '../../components/coupons/NewCouponForm'
export default {
  name: 'Coupons',
  components: { NewCouponForm },
  data () {
    return {
      headers: [
        {
          text: 'Kód',
          value: 'code'
        },
        {
          text: 'Typ',
          value: 'type'
        },
        {
          text: 'Platnost',
          value: 'dateSpan'
        },
        {
          text: 'Počet použití',
          value: 'uses'
        },
        {
          text: 'Min. částka',
          value: 'minPrice'
        }
      ],
      loading: true,
      loadingUsers: false,
      coupons: [],
      paginationOptions: {},
      users: []
    }
  },
  watch: {},
  methods: {
    getCoupons () {
      this.loading = true
      this.$http.get('admin/coupons', {
        params: {
          ...this.options
        }
      })
        .then(res => {
          this.coupons = res.data.coupons
            .map(coupon => {
              coupon.dateSpan = `${(new Date(coupon.validFrom)).toLocaleDateString('cs-CZ')} - ${(new Date(coupon.validTo)).toLocaleDateString('cs-CZ')}`
              return coupon
            })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getUsers () {
      this.loadingUsers = true
      this.$http.get('/admin/users/all')
        .then(res => {
          this.loadingUsers = false
          this.users = res.data
        })
    }
  },
  mounted () {
    this.getUsers()
    this.getCoupons()
  }
}
</script>

<style scoped>

</style>
