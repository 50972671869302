<template>
  <v-form ref="form" v-model="formValid">
    <v-row>
      <v-col>
        <v-text-field
          label="Kód"
          dense
          outlined
          v-model.trim="form.code"
          class="rounded-0"
          :rules="[validation.required, validation.maxLength(255)]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-select
          label="Typ kupónu"
          dense
          :items="types"
          v-model="form.type"
          outlined
          :rules="[validation.required]"
          class="rounded-0"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="form.value"
          :rules="[validation.required]"
          class="rounded-0"
          dense
          label="Hodnota"
          outlined
          type="number"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-select
          label="Vyberte uživatele"
          multiple
          v-model="form.user_ids"
          dense
          :items="users"
          item-text="email"
          item-value="id"
          outlined
          :rules="[validation.required]"
          class="rounded-0">
        </v-select>
      </v-col>
      <v-col>
        <!-- TODO: layout :)))))))))))) -->
        <vc-date-picker
          v-model="datePicker"
          :popover="{ visibility: 'focus' }"
          color="blue"
          is-range
          locale="en-GB">
          <template v-slot="{ datePicker: inputValue, inputEvents }">
            <div class="d-flex justify-center items-center">
              <v-text-field
                v-on="inputEvents.start"
                :rules="[validation.required]"
                :value="getLocalizedDate(datePicker.start)"
                class="rounded-0 border-right-none"
                dense
                hide-details
                outlined
                placeholder="Od"
                readonly
                style="max-width: 120px"
              />
              <v-text-field
                v-on="inputEvents.end"
                :rules="[validation.required]"
                :value="getLocalizedDate(datePicker.end)"
                class="rounded-0 border-left-none"
                dense
                hide-details
                outlined
                placeholder="Do"
                readonly
                style="max-width: 120px"
              />
            </div>
          </template>
        </vc-date-picker>
      </v-col>
      <v-col>
        <v-text-field
          label="Počet použití"
          dense
          v-model.number="form.uses"
          outlined
          :rules="[validation.required]"
          class="rounded-0"
          type="number"
          min="1"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Minimální částka"
          dense
          outlined
          v-model="form.minPrice"
          class="rounded-0"
          type="number"
          :rules="[validation.required]"
          min="0"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-btn
          :disabled="!formValid || runningRequest"
          color="primary"
          @click="save"
          class="rounded-0">
          Uložit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NewCouponForm',
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      runningRequest: false,
      form: {
        code: null,
        type: null,
        user_ids: [],
        uses: 1,
        minPrice: 0
      },
      formValid: true,
      datePicker: {
        start: new Date(),
        end: new Date((new Date()).getTime() + (1000 * 60 * 60 * 24))
      },
      types: [
        {
          text: 'Sleva v %',
          value: 1
        },
        {
          text: 'Částka',
          value: 2
        }
      ]
    }
  },
  methods: {
    save () {
      this.$http.post('/admin/coupons', {
        ...this.form,
        validFrom: this.extractDate(this.datePicker.start),
        validTo: this.extractDate(this.datePicker.end)
      })
        .then(({ data }) => {
          this.$refs.form.reset()
          this.$emit('coupon-saved')
        })
        .catch(({ response }) => {
        })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  }
}
</script>

<style scoped>

</style>
